<template>
  <div>
    <md-tabs :md-active-tab="current_tab" @md-changed="listenerChangeTab">
      <md-tab id="tab-detalle" md-label="Detalles">
        <md-table>
          <md-table-toolbar>
            <h1 class="md-title">Lista ventas
              <p class="md-caption">Total items: {{list_data.mdData.length}}</p>
            </h1>
              <!--<md-menu md-size="big" md-direction="bottom-end" >
                <md-button @click.prevent="showDialog_file=true" >
                  <md-icon>backup</md-icon>
                </md-button>
              </md-menu>-->
            <md-menu md-size="big" md-direction="bottom-end" >
              <md-button class="md-icon-button" @click="go_to('/period-sale')" >
                <md-icon>keyboard_backspace</md-icon>
              </md-button>
            </md-menu>
            <md-menu md-size="auto" >
              <md-button @click.prevent="handler_open_filter()" class="md-icon-button">
                <md-icon>filter_list</md-icon>
              </md-button>
            </md-menu>
            <md-menu md-size="auto">
              <md-button title="Exportar a excel" class="md-icon-button"
              @click.prevent="printer(null, 'excel')">
                <md-icon>grid_on</md-icon>
              </md-button>
            </md-menu>
            <md-menu md-size="auto">
            <md-button  @click.prevent="printer()" title="Exportar a PDF" >
              <md-icon v-if="!sending_request">printer</md-icon>
                <md-progress-spinner v-if="sending_request" class="md-primary"
                :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
              </md-button>
            </md-menu>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button"
                @click="go_to('/form_detalle_ventas/nuevo')">
                <md-icon>add</md-icon>
              </md-button>
            </md-menu>
          </md-table-toolbar>
          <md-table-row>
            <md-table-head>Periodo</md-table-head>
            <md-table-head>Fecha doc</md-table-head>
            <md-table-head>Serie</md-table-head>
            <md-table-head>Número</md-table-head>
            <md-table-head>Cliente</md-table-head>
            <md-table-head>Tipo doc</md-table-head>
            <md-table-head>Total</md-table-head>
            <md-table-head>IVA</md-table-head>
            <md-table-head>Bienes </md-table-head>
            <md-table-head>Servicios </md-table-head>
            <md-table-head>Acción</md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, index) in list_data.mdData" :key="item.id"
          :class="{ 'null error-text': !item.status, 'null error-text': !item.conta_aplica}">
            <md-table-cell class="md-xsmall-hide md-small-hide " >
              {{ item.period_date|format_short_date }}
              <br>
              <md-chip class="md-primary" style="font-size:9px;">{{item.store_name}}</md-chip>
            </md-table-cell>
            <md-table-cell>{{ item.date_invoice|to_date_short }}
              <br>
              <md-chip style="font-size: 8px;" title="Fecha creado">
                <md-icon style="font-size: 16px !important;">history</md-icon>
              {{item.created_at|format_date_time}}</md-chip>
              <md-icon class="md-size-1x" v-if="item.place === 'S' ||  item.place === 'P'">
              store</md-icon>
            </md-table-cell>
            <md-table-cell >{{item.dte_serie}}
              <br>
              <md-chip>#{{item.correlative}}</md-chip>
            </md-table-cell>
            <md-table-cell >{{item.dte_numero}}</md-table-cell>
            <md-table-cell>{{ item.customer_name|verify_value }}
              <br>
              <md-chip class="md-accent" v-if="!item.status">Anulada</md-chip>
            </md-table-cell>
            <md-table-cell>{{ item.type_document_display }}
              <br>
              <span class="md-caption" style="font-size:9px;">
                <md-icon  class="md-size-1x">person</md-icon>
              {{ item.user_name }}</span>
            </md-table-cell>
            <md-table-cell>{{ item.amount|formatPrice }}</md-table-cell>
            <md-table-cell >{{ item.iva|formatPrice }}</md-table-cell>
            <md-table-cell >{{item.goods|formatPrice}}</md-table-cell>
            <md-table-cell >{{item.services|formatPrice}}</md-table-cell>
            <md-table-cell >
              <md-button class="md-icon-button" @click="go_to(`/form_detalle_ventas/${item.id}`)">
                <md-icon class="md-size-1x" >edit</md-icon>
              </md-button>
              <md-button class="md-icon-button"
                @click="go_to(`/form_detalle_ventas/view/${item.id}`)">
                <md-icon class="md-size-1x" >visibility</md-icon>
              </md-button>
              <md-button :disabled="!item.conta_aplica"
              class="md-icon-button" @click="delete_document(item, index)">
                  <md-icon class="md-size-1x">delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
      </md-table>
      <md-empty-state
        md-icon="free_breakfast" v-if="list_data.mdData.length === 0"
        md-label="Crear una venta"
        md-description="Aún no hay ventas registradas, crea al primera.">
        <md-button md-theme="blue-button" class="md-accent md-raised"
          @click="go_to('/form_detalle_ventas/nuevo')"
        >Crear venta</md-button>
      </md-empty-state>
    </md-tab>
    <md-tab id="tab-libros" md-label="Libros">
      <md-table v-model="list_data_book.mdData" class="margin"
        md-sort="name" md-sort-order="asc" md-card md-fixed-header>
          <md-table-toolbar>
            <h1 class="md-title">Libro Ventas</h1>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to('/')">
                <md-icon>keyboard_backspace</md-icon>
              </md-button>
              <md-menu md-size="big" md-direction="bottom-end">
                <md-button class="md-icon-button" @click="go_to('/form_ventas')">
                  <md-icon>add</md-icon>
                </md-button>
              </md-menu>
              <md-menu md-size="auto">
                <md-button @click.prevent="showDialog_book=true">
                  <md-icon>filter_list</md-icon>
                </md-button>
              </md-menu>
            </md-menu>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Autorizacion"
            md-sort-by="authorization">{{item.authorization }}</md-table-cell>
            <md-table-cell md-label="Ejercicio fiscal" md-sort-by="fiscal_exercise">
            {{ item.year }}</md-table-cell>
            <md-table-cell md-label="Fecha autorización"
            md-sort-by="date_authorization">{{ item.date_authorization|to_date }}</md-table-cell>
            <md-table-cell md-label="Establecimiento"
            md-sort-by="store_name">{{ item.store_name }}</md-table-cell>
            <md-table-cell md-label="Activo"
              md-sort-by="status">{{ item.status|status_to_text }}</md-table-cell>
              <md-table-cell md-label="Seleccinar"  title="Click para elegir este libro">
                <md-button class="md-icon-button md-primary" @click="set_book(item)">
                  <md-icon class="md-size-2x" >done</md-icon>
                </md-button>
              </md-table-cell>
            <md-table-cell md-label="Creado por"
              md-sort-by="creator_name" v-if="item.creator_name">
            {{ item.creator_name }}</md-table-cell>
            <md-table-cell md-label="Creado por"
              md-sort-by="creator_name" v-if="!item.creator_name">-----</md-table-cell>
            <md-table-cell md-label="Editar" >
              <md-button class="md-icon-button" @click="go_to(`/form_ventas/${item.id}`)">
                <md-icon class="md-size-1x" >edit</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <md-empty-state
          md-icon="free_breakfast" v-if="list_data_book.mdData.length === 0"
          md-label="Crear el libro de ventas"
          md-description="Aún no hay libros registrados, crea el primero.">
          <md-button
          md-theme="blue-button" class="md-accent
          md-raised" @click="go_to('/form_ventas')">Crear libro de ventas</md-button>
        </md-empty-state>
      </md-tab>
    </md-tabs>
    <md-progress-bar md-mode="indeterminate" v-if="sending_request" />
    <md-dialog :md-active.sync="showDialog_detalle">
      <md-dialog-title>Filtro libro ventas</md-dialog-title>
      <md-card-content>
          <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100 md-size-20">
                  <md-field >
                    <label for="period">Año fiscal</label>
                    <md-select v-model="fiscal_year_id" @md-selected="get_list_period(true)">
                    <md-option value="">----</md-option>
                    <md-option :value="item.id"
                      v-for="(item, key) in list_years"
                      :key="key">{{item.year}}</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-20">
                  <md-field>
                    <label for="period">Periodos</label>
                    <md-select v-model="filters_detalle.period_id">
                    <md-option value="">----</md-option>
                    <md-option :value="item.id"
                      v-for="(item, key) in list_periods"
                      :key="key">{{item.period}}</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-20">
                  <md-field >
                    <label for="total">Total</label>
                    <md-input @keyup.enter="list(1, rowsPerPage)"
                    v-model="filters_detalle.total" type="number" :disabled="sending_request" />
                  </md-field>
                </div>
                <div class="md-layout-item md-size-40  md-small-size-100">
                  <md-field >
                    <label for="total">Tipo documento</label>
                    <md-select v-model="filters_detalle.type_document"
                    name="movie"
                    id="movie"
                    :disabled="sending"
                    >
                      <md-option :value="item.value"
                      v-for="(item, key) in choosen_type_document"
                      :key="key">{{item.text}}</md-option>
                    </md-select>
                  </md-field>
                </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-20 md-small-size-100">
              <md-field class="md-focused">
                <label for="limit">Límite</label>
                <md-input @keyup.enter="list()"
                v-model="filters_detalle.limit" type="number" :disabled="sending_request" />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field >
                <label for="serie">Serie</label>
                <md-input @keyup.enter="list()"
                v-model="filters_detalle.dte_serie" type="text" :disabled="sending_request" />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field >
                <label for="dte_numero">Número</label>
                <md-input @keyup.enter="list()"
                v-model="filters_detalle.dte_numero" type="text" :disabled="sending_request" />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field >
                <label for="dte_numero">Correlativo</label>
                <md-input @keyup.enter="list()"
                v-model="filters_detalle.correlative" type="text" :disabled="sending_request" />
              </md-field>
            </div>
            <div class="md-layout-item md-size-20 md-small-size-100">
              <md-field class="md-focused">
                <label for="date_invoice">Fecha</label>
                <md-input @keyup.enter="list()"
                v-model="filters_detalle.date_invoice" type="date" :disabled="sending_request" />
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field >
                <label for="name">Cliente</label>
                <md-input @keyup.enter="list()"
                v-model="filters_detalle.customer_name" type="text" :disabled="sending_request" />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="stores">Establecimientos</label>
                <md-select v-model="filters_detalle.store">
                  <md-option value="">----</md-option>
                  <md-option :value="item.id"
                    v-for="(item, key) in list_stores"
                    :key="key">{{item.name}}</md-option>
                  </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-25 md-small-size-100" >
              <div class="md-layout-item md-small-size-100">
                <md-checkbox v-model="filters_detalle.services">
                SOLO SERVICIOS</md-checkbox>
              <span v-if="filters_detalle.services" style="color: #ff5252;
              font-weight: 500;" for="services">SÍ</span>
              <span v-if="!filters_detalle.services" style="color: #ff5252;
              font-weight: 500;" for="services">NO</span>
              </div>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100" >
              <div class="md-layout-item md-small-size-100">
                <md-checkbox v-model="filters_detalle.goods">
                SOLO BIENES</md-checkbox>
              <span v-if="filters_detalle.goods" style="color: #ff5252;
              font-weight: 500;" for="goods">SÍ</span>
              <span v-if="!filters_detalle.goods" style="color: #ff5252;
              font-weight: 500;" for="goods">NO</span>
              </div>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-checkbox v-model="filters_detalle.status">ACTIVA</md-checkbox>
            <span v-if="filters_detalle.status" style="color: #ff5252;
            font-weight: 500;" for="status">SÍ</span>
            <span v-if="!filters_detalle.status" style="color: #ff5252;
            font-weight: 500;" for="status">NO</span>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-125">
              <md-field >
                <label for="num_contabilizado"># contabilizado</label>
                <md-input @keyup.enter="list()"
                v-model="filters_detalle.num_contabilizado"
                type="text" :disabled="sending_request" />
              </md-field>
            </div>
          </div>
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog_detalle = false">Cerrar</md-button>
        <md-button class="md-primary" @click="reset_filters('filters_detalle')">
        Limpiar filtro</md-button>
        <md-button class="md-primary" @click="list(1, rowsPerPage)">Filtrar</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="showDialog_book">
      <md-dialog-title>Filtro libros</md-dialog-title>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="name">Número autorizacion</label>
              <md-input @keyup.enter="list_book(1, rowsPerPage)"
              v-model="filters_book.authorization" type="text" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field class="md-focused">
              <label for="date_authorization">Fecha autorización</label>
              <md-input @keyup.enter="list_book(1, rowsPerPage)"
              v-model="filters_book.date_authorization" type="date" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="stores">Establecimientos</label>
              <md-select v-model="filters_book.store">
                <md-option value="">----</md-option>
                <md-option :value="item.id"
                  v-for="(item, key) in list_stores"
                  :key="key">{{item.name}}</md-option>
                </md-select>
            </md-field>
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog_book = false">Cerrar</md-button>
        <md-button class="md-primary" @click="reset_filters('filters_book')">
        Limpiar filtro</md-button>
        <md-button class="md-primary" @click="list_book(1, rowsPerPage)">Filtrar</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="showDialog_file">
      <md-dialog-title>Subir archivo excel</md-dialog-title>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <upload
                url = "upload_batch_documenst_custom/"
                :periodo = "periodo"
            ></upload>
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog_file = false">Cerrar</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import Swal from 'sweetalert2';
/* eslint-disable */
export default {
  name: 'DetalleVentas',
  created(){
    let current_filters_book = this.get_data_storage(this.name_storage_filter_book)
    if(current_filters_book){
      this.filters_book = current_filters_book
    }
    let current_filters_detalle = this.get_data_storage(this.name_storage_filter_detalle)
    if(current_filters_detalle){
      this.filters_detalle = current_filters_detalle
    }

    document.addEventListener('keyup', e => {
      if (e.key === 'f4' || e.keyCode === 115) {
        if(this.current_tab === 'tab-detalle'){
          this.showDialog_detalle = true
        }
        if(this.current_tab === 'tab-libros'){
          this.showDialog_book = true
        }
      }
    })
    this.periodo = this.get_period()
    const yearSelected = this.get_data_storage_raw('id_fiscal_year');
    if (yearSelected) {
      this.fiscal_year_id = yearSelected;
    }

    this.list(1, this.rowsPerPage, this.instance.id)
  },
  components: {
    upload: () => import('../components/UploadFile.vue'),
  },
  data() {
    return {
      showDialog_file: false,
      showDialog_detalle: false,
      showDialog_book: false,
      iva_sobre_total: false,
      current_tab: 'tab-detalle',
      name_storage_filter_detalle: 'filter_sale_detalle',
      name_storage_filter_book: 'filter_sale_book',
      periodo: null,
      fiscal_year_id: null,
      list_data: {
        mdCount: null,
        mdPage: null,
        mdData: []
      },
      list_data_book: {
        mdCount: null,
        mdPage: null,
        mdData: []
      },
      file: null,
      rowsPerPage: 10,
      filters_detalle: { limit: 50 },
      filters_book: { limit: 50},
      instance: {
        id: null
      },
      list_periods: [],
      list_years: [],
      list_stores: [],
      sending: false,
      showDialog: false,
      show_list: true,
      rowsPerPage: 10,
      url: 'sale_book_detail/',
      url_book: 'sale_book/',
      url_fiscal_year: 'fiscal_year',
      sending_request: false,
      choosen_type_document: [
        {value: '', text: '----'},
        {value:'D', text: 'DTE FACTURA'},
        {value:'F', text: 'FACTURA'},
        {value:'P', text: 'DTE FACTURA PEQUEÑO CONTRIBUYENTE'},
        {value:'Q', text: 'FACTURA PEQUEÑO CONTRIBUYENTE'},
        {value:'A', text: 'DTE FACTURA CAMBIARIA'},
        {value:'Y', text: 'FACTURA CAMBIARIA'},
        {value:'J', text: 'DTE FACTURA CAMBIARIA PEQUEÑO CONTRIBUYENTE'},
        {value:'R', text: 'DTE NOTA CRÉDITO'},
        {value:'G', text: 'NOTA CRÉDITO'},
        {value:'B', text: 'DTE NOTA DÉBITO'},
        {value:'K', text: 'NOTA DÉBITO'},
        {value:'M', text: 'COMPROBANTE'},
        {value:'E', text: 'DTE FACTURA ESPECIAL'},
        {value:'S', text: 'FACTURA ESPECIAL'},
      ],
      choosen_field: [
        {value:'customer__juridica__name', text: 'Cliente'},
        {value:'fecha', text: 'Fecha'},
        {value:'amount', text: 'Total'},
        {value:'type_document', text: 'Tipo docuemnto'},
        {value:'iva', text: 'Iva'},
      ],
      choosen_criterio: [
        {value:'=', text: 'Igual'},
        {value:'>', text: 'Mayor'},
        {value:'<', text: 'Menor'},
        {value:'!=', text: 'Diferente'},
      ]
    };
  },
  methods: {
    handler_open_filter() {
      this.get_list_years();
      this.get_list_period()
      this.get_list_stores()
      this.showDialog_detalle = true
    },
    get_list_years() {
      const payload = {};
      const self = this;
      this.sendRequest_with_url(this.url_fiscal_year, payload).then((data) => {
        self.sending_request = false;
        self.list_years = data.data;
      }).catch((error) => {
        self.sending_request = false;
        self.handlerError(error);
      });
      return null;
    },
    get_list_period(set_null=false) {
      const payload = {};
      const self = this;
      if (set_null){
        this.filters_detalle.period_id = null 
      }
      payload.fiscal_year = this.fiscal_year_id
      if (!this.fiscal_year_id) return false
      self.sendRequest_with_url('periods/', payload).then((data) => {
        self.list_periods = data.data;
        self.sending_request = false
      }).catch((error) => {
        self.sending_request = false
        self.handlerError(error)
      });
      return null;
    },
    get_list_stores() {
      if (!this.$store.state.company_obj){
        this.$swal.fire('Error!', "Elegir un contribuyente antes", 'error')
        return false
      }
      let payload = {};
      const self = this;
      payload.company = this.$store.state.company_obj.id
      this.sendRequest_with_url('stores/', payload).then((data) => {
        self.list_stores = data.data
        this.sending_request = false
        this.showDialog = false
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)
      });
      return null;
    },
    set_book (instance) {
      if(!instance.status) {
        this.$swal.fire('Error!', 'No se puede elegir este libro, esta inactivo.', 'error')
        return false
      }
      this.remove_storage('book_sale')
      this.save_storage('book_sale', instance)
      let libro = this.get_data_storage('book_sale')
      if (libro){
        this.$swal.fire('Seleccionado!', 'El libro ha sido Seleccionado.', 'success')
        return false
      }
      this.$swal.fire('Error!', 'Paso algo y el libro no fue elegido.', 'error')

    },
    listenerChangeTab(tab_id){
      this.current_tab = tab_id

      if (tab_id==='tab-libros' && this.list_data_book.mdData.length === 0){
        this.list_book(1, this.rowsPerPage)
      }
    },
    get_period(){
      return localStorage.getItem('periodSale')
    },
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    get_data_storage_raw(name) {
      return localStorage.getItem(name);
    },
    reset_filters: function (name_filter) {
      this[name_filter] = {limit: 50}
      //this.remove_storage(name_filter)
      if (name_filter == 'filters_detalle') {
        this.list()
      }
      if (name_filter == 'filters_book') {
        this.list_book()
      }
    },
    list_book(page, pageSize, sort, sortOrder) {
      if (!this.$store.state.company_obj){
        this.$swal.fire('Error!', "Elegir un contribuyente antes", 'error')
        return false
      }
      let payload = {};
      const self = this;
      payload.paginate = "True"
      payload.page = page
      payload.per_page = pageSize
      if (this.sending_request) return false
      this.sending_request = true
      payload.company = this.$store.state.company_obj.id
      if(this.$store.state.store_obj){
        payload.store = this.$store.state.store_obj.id
      }

      if (Object.keys(this.filters_book).length > 0){
        Object.assign(payload, this.filters_book)
      }
      if (!payload.store){
        delete payload.store
      }
      this.sendRequest_with_url(this.url_book, payload).then((data) => {
        self.rowsPerPage = 50
        self.list_data_book = {
          mdCount: data.data.count,
          mdPage: page,
          mdData: data.data.results
        }
        // self.list_data = data.data;
        this.sending_request = false
        this.showDialog_book = false
         this.save_storage(this.filter_purchase_book, this.filters_book,)
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)
      });
      return null;
    },
    printer(rango=null, format='pdf') {
      if (!this.$store.state.company_obj){
        this.$swal.fire('Error!', "Elegir un contribuyente antes", 'error')
        return false
      }
      let payload = {};
      payload.printer = "True"
      if (format==='excel') {
        payload.printer = "excel"
      }
      payload.company = this.$store.state.company_obj.id
      rango = 'month'
      payload.rango  = rango

      if (rango === 'year'){
        payload.fecha = moment().format('YYYY-MM-DD');
      }
      if (rango === 'month'){
        payload.fecha = moment().startOf('month').format('YYYY-MM-DD');
      }
      if (rango === 'last_year'){
        payload.fecha = moment().subtract(1, 'years').date(1).format('YYYY-MM-DD');
      }
      if (rango === 'last_month'){
        payload.fecha = moment().subtract(1, 'months').date(1).format('YYYY-MM-DD');
      }
      payload.fiscal_year = this.fiscal_year_id

      if(this.filters_detalle.period_id) {
        payload.period_id = this.filters_detalle.period_id;
      }
      if(this.filters_detalle.store) {
        payload.store = this.filters_detalle.store;
      }
      if(this.filters_detalle.type_document) {
        payload.type_document = this.filters_detalle.type_document;
      }
      if (this.iva_sobre_total){
        payload.iva_sobre_total = "True"
      }

      //payload.id = localStorage.getItem('period')
      //payload.periodo = localStorage.getItem('date_period_i').replace(/['"]+/g, '')

      if (rango == null){
        if(this.filters_detalle.al) {
          delete payload.rango
          delete payload.fecha
        }
        if(this.filters_detalle.del) {
          delete payload.rango
          delete payload.fecha
        }
      }
      if (rango != null){
        delete payload.del
        delete payload.al
      }
      if (this.sending_request) return false
      this.sending_request = true

      this.sendRequest_with_url(this.url, payload).then((data) => {
        if (data.headers['content-type'] == 'application/pdf'){
          this.sending_request = false
          var blob = new Blob([data.data], { type: 'application/pdf;charset=utf-8' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'report_sale_book.pdf';
          window.open(link, '_blank');
          return false;
        }
        if (payload.printer==='excel') {
          this.to_excel(data)
          return false;
        }
        this.sending_request = false
      }).catch((error) => {
        console.log(error)
        this.sending_request = false
        this.handlerError(error)
      });
    },
    to_excel(data) {

      let datetimenow = new Date();
      let datenow = datetimenow.getDate() + '-'+datetimenow.getMonth() + '-' + datetimenow.getFullYear();
      let timenow = datetimenow.getHours() + datetimenow.getMinutes() +   datetimenow.getSeconds();
      let nameexcel ='DetalleVentas '+datenow+' '+timenow;      

      var uri = 'data:application/vnd.ms-excel;base64,';
      var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'; 
      var base64 = function(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      };
      var format = function(s, c) {
        return s.replace(/{(\w+)}/g, function(m, p) {
          return c[p];
        })
      };

      var htmls = data.data;

      var ctx = {
        worksheet : 'Worksheet',
        table : htmls
      }

      var link = document.createElement("a");
      link.pars;
      link.download = nameexcel+".xls";
      link.href = uri + base64(format(template, ctx));
      link.click();
      this.sending_request = false
    },
    list() {
      if (!this.$store.state.company_obj){
        this.$swal.fire('Error!', "Elegir un contribuyente antes", 'error')
        return false
      }
      
      let payload = {};
      const self = this;
      if (this.sending_request) return false
      this.sending_request = true
      payload.company = this.$store.state.company_obj.id
      if (this.$store.state.store_obj){
        // payload.store = this.$store.state.store_obj.id        
      }
      if (Object.keys(this.filters_detalle).length > 0){
        Object.assign(payload, this.filters_detalle)
      }
      if (!payload.type_document){
        delete payload.type_document
      }
      if (!this.filters_detalle.period_id) {
        delete payload.period_id
      }
      if (!this.filters_detalle.store) {
        delete payload.store
      }
      if (!this.filters_detalle.dte_numero) {
        delete payload.dte_numero
      }
      if (!this.filters_detalle.correlative) {
        delete payload.correlative
      }

      if (!this.filters_detalle.total) {
        delete payload.total
      }
      if (!this.filters_detalle.dte_serie) {
        delete payload.dte_serie
      }
      if (this.filters_detalle.status) {
        payload.status = "True"
      }
      if (!this.filters_detalle.status && this.filters_detalle.status != undefined) {
        payload.status = "False"
      }
      if (this.iva_sobre_total){
        payload.iva_sobre_total = "True"
      }
      
      this.sendRequest_with_url(this.url, payload).then((data) => {
        self.list_data = {
          mdData: data.data
        }
        this.save_storage(this.name_storage_filter_detalle, this.filters_detalle,)
        this.sending_request = false
        this.showDialog_detalle = false
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)
      });
      return null;
    },
    delete_document(item, index){
      if (item.id){
        const self = this
        Swal.fire({
            title: "¿Esta seguro?",
            text: "El registro será deshabilitado y no se podrá recuperar.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            showLoaderOnConfirm: true,
            preConfirm: function (email) {
                const payload = item
               return self.deleteRequestDocument(payload).then(function (data){
                  Swal.fire(
                    'Anulado!',
                    'El registro ha sido deshabilitado.',
                    'success'
                  )
                  self.sending = false
                  self.$set(self.list_data.mdData, index, data.data)
                }).catch(function (error) {
                  self.sending = false
                  if (typeof (error.response.data) === 'object') {
                    const result = error.response.data
                    Object.entries(result).forEach(
                      ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
                    )
                    return false
                  }
                  self.$swal.fire('Error!', error.response.data, 'error')
                })
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }else {
              item.edit = false
              Swal.fire(
                'Cancelado',
                'El registro esta seguro.',
                'error'
              )

            }
        });
      }
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url,{params:  payload});
      return result;
    },
    async sendRequest_with_url(url, payload) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async deleteRequestDocument(payload) {
      const result = await this.$http.delete(`${this.url}${payload.id}/`, payload)
      return result
    },
    handlerError: function (error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', key + ': ' + value, 'error')
        )
        return false
      }
      this.$swal.fire('Error!', error.response.data, 'error')
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    },
    to_date(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('ll')
    },
    to_date_short(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('DD/MM/YYYY')
    },
    format_short_date: function (value) {                     
      return moment(value, 'YYYY-MM-DD HH:mm:ss', 'es').format('MMMM YYYY').toUpperCase();
    },
    format_date_time: function (value) {                     
      return moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
    },
    verify_value(value) {
      if ( value === '' || value === null ) return '--ANULADA--'
      return value
    },
    status(value) {
      if (value) return 'Si'
      return 'No'
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
};

</script>
<style lang="scss" scoped>
  .md-table + .md-table {
    margin-top: 16px
  }
  .avatar img {
    max-width: 30px;
  }
</style>
<style lang="css" scoped>
  .on-over {
    cursor: pointer;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }
  .null{
    text-decoration: line-through;
  }
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .margin{
    margin-bottom: 60px;
  }
  .md-card-content{
    overflow-y: auto;
  }

  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  .error-text {
    color: #958c8c;
  }
</style>
